import { Image } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Wrapper } from "../../components/ui";
import { HiOutlineSpeakerXMark } from "react-icons/hi2";
import { FONESTAR } from "../../global";

import "./styles/maintenance.scss";

const Maintenance = ({ isMaintenanceMode }) => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper id="pages-maintenance-index" className="body-sign">
      <div className="center-sign">
        <a
          href={process.env.REACT_APP_WP_URL}
          rel="external"
          className="logo float-start"
        >
          <Image
            src={require(`../../assets/img/logo-${i18n.resolvedLanguage}.png`)}
            height="54"
            alt={`${t("area_de_cliente")} - ${FONESTAR.shortName}`}
          />
        </a>

        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="d-inline-flex align-items-center gap-1 title text-uppercase font-weight-bold m-0 bg-primary">
              <span>{t("mantenimiento")}</span>
            </h2>
          </div>
          <div className="card-body border-primary">
            <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
              <HiOutlineSpeakerXMark size="3rem" />
            </div>

            {isMaintenanceMode ? (
              <>
                <p className="font-weight-bold h3 mt-2 mb-3">
                  <span>{t("queremos_mejorar_experiencia")}</span>
                </p>
                <p className="mt-4">
                  <Trans
                    i18nKey="mantenimiento_fin_de_semana"
                    components={{ strong: <strong /> }}
                  />
                </p>
                <p>{t("lamentamos_las_molestas_ocasionadas")}</p>
                <p className="mt-4 text-brand-red font-weight-bold">
                  {t("volveremos_lunes")}
                </p>
                <p
                  style={{ fontSize: "0.8rem" }}
                  className="mt-4 text-brand-light"
                >
                  <i>{t("pd_nos_hemos_puestos_unos_temazos_sigue")}</i>
                </p>
              </>
            ) : (
              <>
                <p className="font-weight-bold h3 mt-2 mb-3">
                  <span>{t("esto_no_nos_suena_bien")}</span>
                </p>
                <p className="mt-4">
                  <Trans
                    i18nKey="por_ello_estamos_afinando_nuestros_sistemas_sigue"
                    components={{ strong: <strong /> }}
                  />
                </p>
                <p>{t("lamentamos_las_molestas_ocasionadas")}</p>
                <p className="mt-4 text-brand-red font-weight-bold">
                  {t("prueba_a_entrar_de_nuevo_en_unos_minutos")}
                </p>
                <p
                  style={{ fontSize: "0.8rem" }}
                  className="mt-4 text-brand-light"
                >
                  <i>{t("pd_nos_hemos_puestos_unos_temazos_sigue")}</i>
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    (window.location.href = window.location.origin)
                  }
                  data-testid="mantenimiento-go-back-button"
                >
                  {t("volver_atras")}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Maintenance;
