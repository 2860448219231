import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SustitutoToastSkeleton = () => {
  return (
    <div>
      <Skeleton className="mb-3" width={350} height={150} data-testid={"skeleton-sustitutos"}/>
    </div>
  );
};
