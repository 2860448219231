import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { addItem, removeItem } from "../api/cart";
import useAuth from "../hooks/useAuth";
import useCart from "../hooks/useCart";
import NotificationsContext from "./Notifications";

/**
 * Este contexto se utiliza para el refresco de los widgets que tengan que ver con el carrito, al estar todo manejado
 * por base de datos y de forma asícrona, es necesario mandar una señal entre componentes para que éstos se refresquen
 * y actualicen el UI.
 *
 * La forma más sencilla es crear un contexto que contenga la fecha de actualización del carrito mientras se utiliza
 * la aplicación y que dicha fecha se actualice al añadir o quitar elementos del carrito.
 *
 * De la misma manera se gestionan los favoritos.
 */

const CartContext = createContext({
  items: [],
  addToCart: () => {},
  removeFromCart: () => {},
});

const CartProvider = (props) => {
  const { token } = useAuth();
  const { t, i18n } = useTranslation();
  const { addNotification } = useContext(NotificationsContext);

  const [totalCart, setTotalCart] = useState(0);
  const [cartUpdated, setCartUpdated] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [shippingCosts] = useState(null);
  const { data: cart, isLoading: cartLoading } = useCart(cartUpdated);
  /* const fullCart = useCart(cartUpdated, true);

  useEffect(() => {
    getTotalCart(cart);
    setShippingCosts(fullCart.Portes);
  }, [cartUpdated, cart, fullCart]); */

  const addToCart = async (
    product,
    units,
    notification = true,
    type,
    location,
    setExternalLoading = undefined
  ) => {
    setIsLoadingData(true)
    if (setExternalLoading) setExternalLoading(true)
    await addItem(
      i18n.resolvedLanguage,
      token.value,
      product,
      units,
      type,
      location,
    )
      .then((response) => {
        if (notification) {
          addNotification(
            t("anadido_X_al_carrito", { product: product }),
            `${t("se_ha_anadido_X_del_producto_Y_al_carrito", {
              units: units,
              product: product,
              count: units,
            })}.`,
            "success",
          );
        }

        setCartUpdated(new Date().toISOString());
      })
      .catch((error) => {
        addNotification(
          t("error_al_anadir_el_producto"),
          error.info["status-details"] ??
            `${t("no_se_ha_podido_anadir_el_producto_X_al_carrito", {
              product: product,
            })}.`,
          "danger",
        );
        /*   localStorage.removeItem("cartLoad"); */
      })
      .finally(() => {
        setIsLoadingData(false)
        if (setExternalLoading) setExternalLoading(false)
      });
  };

  const removeFromCart = async (
    product,
    location = "",
    notification = true,
  ) => {
    setIsLoadingData(true)
    await removeItem(i18n.resolvedLanguage, token.value, product, location)
      .then(() => {
        if (notification) {
          addNotification(
            t("eliminado_X_del_carrito", { product: product }),
            `${t("se_ha_eliminado_el_producto_X_del_carrito", {
              product: product,
            })}.`,
            "success",
          );
        }
        setCartUpdated(new Date().toISOString());
      })
      .catch((error) => {
        addNotification(
          t("error_al_quitar_el_producto"),
          error.info["status-details"] ??
            `${t("no_se_ha_podido_quitar_el_producto_X_del_carrito", {
              product: product,
            })}.`,
          "danger",
        );
      })
      .finally(() => {
        setIsLoadingData(false)
      });
  };

  const getTotalCart = (cart) => {
    setTotalCart(
      cart.ok?.reduce((sum, product) => {
        return sum + product.Cantidad * product.Precios.Neto.Importe;
      }, 0),
    );
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        getTotalCart,
        totalCart,
        setCartUpdated,
        shippingCosts,
        isLoading: cartLoading || isLoadingData
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export { CartProvider };
export default CartContext;
