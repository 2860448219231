import { API_URL } from "../../global"
import { extractItemsFromApiRequest } from "../../helpers"

export const getArticle = async (token, code, setIsLoading = undefined) => {
  if (setIsLoading) setIsLoading(true)

  try {
    const response = await fetch(`${API_URL}/articulos/${code}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          fstoken: token.value,
          "Content-Type": "application/json",
          "X-API-KEY": process.env.REACT_APP_X_API_KEY
        },
      },
    )

    const data = await response.json()

    return extractItemsFromApiRequest(data)

  } catch (error) {
    console.log('Error: ', error)
  } finally {
    if (setIsLoading) setIsLoading(false)
  }
}
