import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import useAuth from "./useAuth";
import {
  constructInitRequestConfig,
  extractItemsFromApiRequest,
} from "../helpers";
import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";

const useProduct = (code, headers = {}, setIsLoading = undefined) => {
  const { i18n } = useTranslation();
  const { token } = useAuth();
  const { data, isLoading } = useSWR([
    `${API_URL}/articulos/${code}`,
    constructInitRequestConfig(DEFAULT_REQUEST_CONFIG, {
      lng: i18n.resolvedLanguage,
      fstoken: token.value,
      ...headers,
    }),
  ]);

  useEffect(() => {
    if (setIsLoading) setIsLoading(isLoading)
  }, [isLoading, setIsLoading])

  return extractItemsFromApiRequest(data);
};

export default useProduct;
