import { Fragment } from "react";
import { useTimeout } from "usehooks-ts";
import { FaCheck, FaExclamation, FaInfo, FaTimes } from "react-icons/fa";

const Icon = ({ variant }) => {
  switch (variant) {
    case "success":
      return <FaCheck />;
    case "danger":
      return <FaTimes />;
    case "warning":
      return <FaExclamation />;
    case "info":
      return <FaInfo />;
    default:
      return <FaInfo />;
  }
};

export const Notification = ({ title, text, variant, handleClick }) => {
  const hide = () => handleClick();

  useTimeout(hide, 10000);

  return (
    <div
      className="ui-pnotify click-2-close"
      role="button"
      onClick={handleClick}
    >
      <div
        className={`p-relative mb-2 notification ui-pnotify-container notification-${variant}`}
      >
        <div className="ui-pnotify-closer float-end">
          <FaTimes />
        </div>
        <div className="ui-pnotify-icon">
          <span>
            <Icon variant={variant} />
          </span>
        </div>
        <h4
          className="ui-pnotify-title fw-bold"
          data-testid="notification-title"
        >
          {title}
        </h4>
        <div className="ui-pnotify-text" data-testid="notification-text">
          {Array.isArray(text)
            ? text.map((line, idx) => (
                <Fragment key={`text-${idx}`}>
                  · {line}.
                  <br />
                </Fragment>
              ))
            : text}
        </div>
      </div>
    </div>
  );
};
