import { useTranslation } from "react-i18next";
import useSWR from "swr";

import useAuth from "./useAuth";

import {
  constructInitRequestConfig,
  extractItemsFromApiRequest,
} from "../helpers";
import { API_URL, DEFAULT_REQUEST_CONFIG } from "../global";

/**
 * @description Hook para sacar los datos de los productos en el carrito.
 * @param {string|null} updated Se utiliza de clave de la petición para que la caché no de problemas.
 * @param {boolean} allData Devuelve o no toda la información del carrito, como los gastos de envío o los puntos acumulados de las promociones.
 * @returns
 */
const useCart = (updated, allData = false) => {
  const { i18n } = useTranslation();
  const { token } = useAuth();
  const { data, isLoading } = useSWR([
    `${API_URL}/carrito`,
    constructInitRequestConfig(DEFAULT_REQUEST_CONFIG, {
      lng: i18n.resolvedLanguage,
      fstoken: token.value,
    }),
    updated,
  ]);

  return {
    data: allData ? data : extractItemsFromApiRequest(data, false),
    isLoading
  };
};

export default useCart;
